import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Redux Imports
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

// Redux persist
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

// Snackbar Imports
import { SnackbarProvider } from 'notistack';

// Router Imports
import { createBrowserHistory } from 'history';
import { updateLocation } from './location';

// Reducer import
import rootReducer from './store/reducers/rootReducer';

// App config Imports
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Persist config and black-list
const persistConfig = {
	key: 'root',
	storage,
	blacklist: [
		'admin',
		'auth',
		'appOptions',
		'dashboard',
		'innerRouter',
		'kyc',
		'notifications',
		'user',
		'updateDialog',
	],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Store configuration
const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: {
				extraArgument: {},
			},
			serializableCheck: false,
		}),
});

// Update location
const history = createBrowserHistory();

store.unsubscribeHistory = history.listen(updateLocation(store));

// Persistor
const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

const snackbarConfig = {
	vertical: 'top',
	horizontal: 'right',
};

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<SnackbarProvider maxSnack={3} anchorOrigin={snackbarConfig}>
					<App />
				</SnackbarProvider>
			</PersistGate>
		</Provider>
	</BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
