import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

// Redux Imports
import { connect } from 'react-redux';

// MUI Imports
import { Container } from '@mui/material';

// Device Detect
import { isIOS } from 'react-device-detect';

// Styled Components imports
import styled from 'styled-components';

// Styled Components
const Wrapper = styled(Container)({
	display: 'flex !important',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	paddingTop: isIOS ? '75px' : '0',
	height: (props) => (props.$hFull ? '100vh' : 'auto'),
});

const InnerWrapper = styled(Container)({
	maxWidth: '400px !important',
	paddingTop: '20px !important',
});

const WelcomeWrapper = (props) => {
	// Props destructuring
	const { accessToken, children, hFull, userProfile } = props;

	if (accessToken && userProfile) return <Navigate replace to='/cuenta' />;

	return (
		<Wrapper $hFull={hFull}>
			<InnerWrapper>{children}</InnerWrapper>
		</Wrapper>
	);
};

WelcomeWrapper.propTypes = {
	accessToken: PropTypes.string,
	children: PropTypes.node.isRequired,
	hFull: PropTypes.bool,
	userProfile: PropTypes.object,
};

const mapStateToProps = (state) => {
	const { accessToken } = state.auth;
	const { userProfile } = state.user;

	return {
		accessToken,
		userProfile,
	};
};

export default connect(mapStateToProps)(WelcomeWrapper);
