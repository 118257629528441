const initState = {
	loading: false,
	loadingLogout: false,
	accessToken: null,
	tokenDuration: null,
	redirectTo: null,
};

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case 'AUTH_START':
			return {
				...state,
				loading: true,
			};
		case 'LOGIN_OUT_START':
			return {
				...state,
				loadingLogout: true,
			};
		case 'SIGNIN_SUCCESS':
			return {
				...state,
				accessToken: action.payload.access_token,
				tokenDuration: action.payload.expires_in,
			};
		case 'SIGNOUT_SUCCESS':
			return {
				...state,
				loading: false,
				loadingLogout: false,
				accessToken: null,
				tokenDuration: null,
				redirectTo: '/bienvenida',
			};
		case 'AUTO_SIGNOUT_SUCCESS':
			return {
				...state,
				loading: false,
				loadingLogout: false,
				accessToken: null,
				tokenDuration: null,
				redirectTo: '/sesion-expirada',
			};
		case 'AUTH_END':
		case 'SIGNIN_ERROR':
		case 'SIGNUP_ERROR':
		case 'SET_USER_DATA_ERROR':
		case 'SEND_CODE_VERIFICATION_SUCCESS':
		case 'SEND_CODE_VERIFICATION_ERROR':
		case 'FORGOT_PASSWORD_ERROR':
			return {
				...state,
				loading: false,
				redirectTo: null,
			};
		case 'SIGNUP_SUCCESS':
		case 'FORGOT_PASSWORD_SUCCESS':
			return {
				...state,
				loading: false,
				loadingLogout: false,
				redirectTo: '/ingresar',
			};
		default:
			return state;
	}
};

export default authReducer;
