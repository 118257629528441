import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';

// Local Imports
import PageLoading from '../shared/PageLoading';
import RouterWrapper from './RouterWrapper';
import SessionExpired from '../pages/SessionExpired/SessionExpired';
import TwoSessions from '../pages/TwoSessions/TwoSessions';
import InactiveUser from '../pages/InactiveUser/InactiveUser';

// Loadable Imports
const Login = loadable(() => import('../pages/Login/Login'));
const MyAccount = loadable(() => import('../pages/Account/MyAccount'));
const NotFound = loadable(() => import('../pages/NotFound/NotFound'));
const ResetPassword = loadable(() =>
	import('../pages/ResetPassword/ResetPassword')
);
const Welcome = loadable(() => import('../pages/Welcome/Welcome'));

const Router = () => {
	return (
		<RouterWrapper>
			<Suspense fallback={<PageLoading />}>
				<Routes>
					<Route exact path='/' element={<Navigate replace to='/cuenta' />} />
					<Route path='/cuenta' element={<MyAccount />} />
					<Route path='/bienvenida' element={<Welcome />} />
					<Route path='/ingresar' element={<Login />} />
					<Route
						path='/registrarse'
						element={<Navigate replace to='/ingresar' />}
					/>
					<Route path='/restablecer-contrasena' element={<ResetPassword />} />
					<Route path='/sesion-expirada' element={<SessionExpired />} />
					<Route path='/sesion-otro-dispositivo' element={<TwoSessions />} />
					<Route path='/usuario-inactivo' element={<InactiveUser />} />

					{/* Not found route */}
					<Route path='*' element={<NotFound />} />
				</Routes>
			</Suspense>
		</RouterWrapper>
	);
};

export default Router;
