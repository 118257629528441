const initState = {
	// External accounts Count
	loadingExternalAccounts: false,
	externalAccountsCount: 0,

	// KYC on verification
	loadingKYCUsersOnVerification: false,
	KYCUsersOnVerificationCount: 0,

	// KYC approved
	loadingKYCUsersApproved: false,
	KYCUsersApprovedCount: 0,

	// Users by date
	loadingUsersByDate: false,
	usersByDateCount: 0,

	// External accounts by type
	loadingExternalAccountsByType: false,
	externalAccountsByType: [],

	// Users by residence country
	loadingUsersByResidenceCountry: false,
	usersByResidenceCountry: [],

	// User by birth country
	loadingUsersByBirthCountry: false,
	usersByBirthCountry: [],
};

const dashboardReducer = (state = initState, action) => {
	switch (action.type) {
		case 'DASHBOARD_START':
			return {
				...state,
				loadingExternalAccounts: true,
				loadingKYCUsersOnVerification: true,
				loadingKYCUsersApproved: true,
				loadingUsersByDate: true,
				loadingExternalAccountsByType: true,
				loadingUsersByResidenceCountry: true,
				loadingUsersByBirthCountry: true,
			};

		// Success
		case 'GET_EXTERNAL_ACCOUNTS_SUCCESS':
			return {
				...state,
				loadingExternalAccounts: false,
				externalAccountsCount: action.payload,
			};
		case 'GET_KYC_ON_VERIFICATION_SUCCESS':
			return {
				...state,
				loadingKYCUsersOnVerification: false,
				KYCUsersOnVerificationCount: action.payload,
			};
		case 'GET_KYC_VERIFIED_SUCCESS':
			return {
				...state,
				loadingKYCUsersApproved: false,
				KYCUsersApprovedCount: action.payload,
			};
		case 'GET_USERS_BY_DATE_SUCCESS':
			return {
				...state,
				loadingUsersByDate: false,
				usersByDateCount: action.payload,
			};
		case 'GET_EXTERNAL_ACCOUNTS_BY_TYPE_SUCCESS':
			return {
				...state,
				loadingExternalAccountsByType: false,
				externalAccountsByType: action.payload,
			};
		case 'GET_USERS_BY_RESIDENCE_COUNTRY_SUCCESS':
			return {
				...state,
				loadingUsersByResidenceCountry: false,
				usersByResidenceCountry: action.payload,
			};
		case 'GET_USERS_BY_BIRTH_COUNTRY_SUCCESS':
			return {
				...state,
				loadingUsersByBirthCountry: false,
				usersByBirthCountry: action.payload,
			};

		// Errors
		case 'GET_EXTERNAL_ACCOUNTS_ERROR':
			return {
				...state,
				loadingExternalAccounts: false,
			};
		case 'GET_KYC_ON_VERIFICATION_ERROR':
			return {
				...state,
				loadingKYCUsersOnVerification: false,
			};
		case 'GET_KYC_VERIFIED_ERROR':
			return {
				...state,
				loadingKYCUsersApproved: false,
			};
		case 'GET_USERS_BY_DATE_ERROR':
			return {
				...state,
				loadingUsersByDate: false,
			};
		case 'GET_EXTERNAL_ACCOUNTS_BY_TYPE_ERROR':
			return {
				...state,
				loadingExternalAccountsByType: false,
			};
		case 'GET_USERS_BY_RESIDENCE_COUNTRY_ERROR':
			return {
				...state,
				loadingUsersByResidenceCountry: false,
			};
		case 'GET_USERS_BY_BIRTH_COUNTRY_ERROR':
			return {
				...state,
				loadingUsersByBirthCountry: false,
			};
		default:
			return state;
	}
};

export default dashboardReducer;
