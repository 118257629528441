const initState = {
	loading: false,
	closeUpdateUserDialog: false,
	closeUpdateConfigurationDialog: false,
	closeUpdateBankDialog: false,
	closeUpdateTransactionDialog: false,
	usersList: [],
	transactionsList: [],
	ticketsList: [],
	ticketHistory: [],
	addMessageError: '',
	configurationsList: [],
	countriesList: [],
	banksList: [],
};

const adminReducer = (state = initState, action) => {
	switch (action.type) {
		case 'ADMIN_ACTION_START':
		case 'USERS_ACTION_START':
			return {
				...state,
				loading: true,
			};
		case 'GET_COUNTRIES_START':
		case 'GET_TICKETS_START':
			return {
				...state,
			};
		// Success
		case 'GET_USERS_LIST_SUCCESS':
			return {
				...state,
				loading: false,
				usersList: action.payload,
			};
		case 'ADMIN_USER_UPDATE_SUCCESS':
			return {
				...state,
				loading: false,
				closeUpdateUserDialog: true,
			};
		case 'GET_TRANSACTIONS_SUCCESS':
			return {
				...state,
				loading: false,
				transactionsList: action.payload,
			};
		case 'UPDATE_TRANSACTION_SUCCESS':
			const initTransactions = [...state.transactionsList];
			const updatedTransactionsList = initTransactions.map((trans) => {
				if (trans.id === action.payload.id) {
					return { ...trans, status: 'Finalizada' };
				}
				return trans;
			});

			return {
				...state,
				loading: false,
				transactionsList: updatedTransactionsList,
				closeUpdateTransactionDialog: true,
			};
		case 'REVERSE_TRANSACTION_SUCCESS':
			const transactions = [...state.transactionsList];
			const updatedTransactions = transactions.map((trans) => {
				if (trans.id === action.payload.id) {
					return { ...trans, status: 'Reversada' };
				}
				return trans;
			});

			return {
				...state,
				loading: false,
				transactionsList: updatedTransactions,
				closeUpdateTransactionDialog: true,
			};
		case 'GET_TICKETS_SUCCESS':
			return {
				...state,
				loading: false,
				ticketsList: action.payload,
			};
		case 'GET_CONFIGURATIONS_SUCCESS':
			return {
				...state,
				loading: false,
				configurationsList: action.payload,
			};
		case 'GET_COUNTRIES_SUCCESS':
			return {
				...state,
				loading: false,
				countriesList: action.payload,
			};
		case 'GET_TICKET_BY_ID_SUCCESS':
		case 'CHANGE_TICKET_STATUS_ERROR':
		case 'ADD_MESSAGE_TICKET_SUCCESS':
			return {
				...state,
				loading: false,
				ticketHistory: action.payload,
			};
		case 'CREATE_CONFIGURATION_SUCCESS':
		case 'UPDATE_CONFIGURATION_SUCCESS':
			return {
				...state,
				loading: false,
				closeUpdateConfigurationDialog: true,
			};
		case 'GET_BANKS_LIST_SUCCESS':
			return {
				...state,
				loading: false,
				banksList: action.payload,
			};
		case 'CREATE_BANK_SUCCESS':
		case 'UPDATE_BANK_SUCCESS':
			return {
				...state,
				loading: false,
				closeUpdateBankDialog: true,
			};
		// Error
		case 'ADMIN_USER_UPDATE_ERROR':
		case 'CHANGE_TICKET_STATUS_SUCCESS':
		case 'CREATE_CONFIGURATION_ERROR':
		case 'CREATE_BANK_ERROR':
		case 'GET_BANKS_LIST_ERROR':
		case 'GET_CONFIGURATIONS_ERROR':
		case 'GET_COUNTRIES_ERROR':
		case 'GET_USERS_LIST_ERROR':
		case 'GET_TRANSACTIONS_ERROR':
		case 'GET_TICKETS_ERROR':
		case 'GET_TICKET_BY_ID_ERROR':
		case 'UPDATE_CONFIGURATION_ERROR':
		case 'UPDATE_BANK_ERROR':
		case 'UPDATE_TRANSACTION_ERROR':
		case 'REVERSE_TRANSACTION_ERROR':
			return {
				...state,
				loading: false,
			};
		case 'ADD_MESSAGE_TICKET_ERROR':
			return {
				...state,
				loading: false,
				addMessageError: action.payload,
			};
		// Reset
		case 'RESET_ADMIN_STATE':
		case 'RESET_MESSAGE_ERROR':
			return {
				...state,
				loading: false,
				closeUpdateUserDialog: false,
				closeUpdateConfigurationDialog: false,
				closeUpdateBankDialog: false,
				closeUpdateTransactionDialog: false,
				addMessageError: '',
			};
		// Default
		default:
			return state;
	}
};

export default adminReducer;
