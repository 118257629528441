import { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Local Components Imports
import SplashScreen from '../layout/SplashScreen';

const RouteWrapper = (props) => {
	// Props destructuring
	const { children } = props;

	// Component state
	const [dataToShow, setDataToShow] = useState(<SplashScreen />);

	// Component hooks
	const location = useLocation();

	useLayoutEffect(() => {
		document.documentElement.scrollTo(0, 0);
	}, [location.pathname]);

	useEffect(() => {
		setTimeout(() => {
			setDataToShow(children);
		}, 1500);
	}, [children]);

	return dataToShow;
};

RouteWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};

export default RouteWrapper;
