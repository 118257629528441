import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI imports
import { Typography } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local components imports
import WelcomeWrapper from '../../shared/WelcomeWrapper';
import Logo from '../../shared/Logo';
import CustomBtn from '../../layout/CustomBtn';

// Styled Components
const Title = styled(Typography)({
	textAlign: 'center',
	fontSize: '1.5rem !important',
	lineHeight: '1.7rem !important',

	'@media (max-width: 280px)': {
		fontSize: '1.5rem !important',
		lineHeight: '1.7rem !important',
	},

	'@media only screen and (min-width: 281px) and (max-width: 320px)': {
		fontSize: '1.6rem !important',
		lineHeight: '2rem !important',
	},
});

const Description = styled(Typography)({
	marginTop: '0.5rem !important',
	marginBottom: '1rem !important',
	textAlign: 'center',
	fontSize: '1rem !important',
	lineHeight: '1.2rem !important',
});

const SessionExpired = () => {
	// Component hooks
	const navigate = useNavigate();

	// Component functions
	const returnToLogin = () => {
		navigate('/ingresar');
	};

	return (
		<WelcomeWrapper hFull useTopPadding={false}>
			<Logo vertical logoHeight='calc(100vh / 6)' />

			<Title variant='h4'>Tu sesión ha expirado</Title>
			<Description variant='h5'>
				El tiempo máximo de la sesión ha expirado por tu seguridad. Por favor
				vuelve a ingresar para continuar.
			</Description>

			<CustomBtn
				btnText={'Volver a ingresar'}
				btnType={'button'}
				fontSize={'1.2rem'}
				onClick={returnToLogin}
				marginTop={'2rem'}
			/>
		</WelcomeWrapper>
	);
};

SessionExpired.propTypes = {};

export default SessionExpired;
