import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// Redux Imports
import { connect } from 'react-redux';

// Material UI Imports
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';

// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Phone input CSS
import 'react-phone-input-2/lib/style.css';

// CSS Imports
import './App.css';
import './App-desktop.css';
import './App-mobile.css';
import './Modified-mui.css';

// Theme
import theme from './layout/Theme';

// Router
import Router from './routes/Router';
import MenuBar from './layout/MenuBar';

// Local Imports
import UpdateDialog from './layout/UpdateDialog';
import useNotifier from './layout/useNotifier';

function App(props) {
	// Props destructuring
	const { accessToken } = props;

	// Component Hooks
	const location = useLocation();
	useNotifier();

	return (
		<LocalizationProvider adapterLocale='es' dateAdapter={AdapterMoment}>
			<ThemeProvider theme={theme}>
				<>
					{/* Menu Bar */}
					{accessToken && location.pathname !== '/bienvenida' && (
						<MenuBar pageTitle='Utransfer' />
					)}

					{/* App router */}
					<Router />

					<UpdateDialog />
				</>
			</ThemeProvider>
		</LocalizationProvider>
	);
}

App.propTypes = {
	accessToken: PropTypes.string,
};

const mapStateToProps = (state) => {
	const { accessToken } = state.auth;
	const { notifications } = state.notifications;

	return {
		accessToken,
		notifications,
	};
};

export default connect(mapStateToProps)(App);
