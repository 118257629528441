const initState = {
	loading: false,
	loadingApprovement: false,
	kycUsersList: [],
	handleCloseDialog: false,
	reloadKycUsersList: false,
};

const kcyReducer = (state = initState, action) => {
	switch (action.type) {
		case 'GET_KYC_FOR_VERIFICATION_START':
			return {
				...state,
				loading: true,
			};
		case 'KYC_VERIFICATION_START':
			return {
				...state,
				loadingApprovement: true,
			};
		// Success
		case 'GET_KYC_FOR_VERIFICATION_SUCCESS':
			return {
				...state,
				loading: false,
				kycUsersList: action.payload,
			};
		case 'KYC_VERIFICATION_SUCCESS':
		case 'KYC_VERIFICATION_ERROR':
			return {
				...state,
				loadingApprovement: false,
				handleCloseDialog: true,
				reloadKycUsersList: true,
			};

		// Errors
		case 'GET_KYC_FOR_VERIFICATION_ERROR':
			return {
				...state,
				loading: false,
			};
		case 'KYC_VERIFICATION_END':
			return {
				...state,
				handleCloseDialog: false,
				reloadKycUsersList: false,
			};
		default:
			return state;
	}
};

export default kcyReducer;
