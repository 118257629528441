import React from 'react';
import PropTypes from 'prop-types';

// MUI Imports
import { Button } from '@mui/material';

// Styled Components imports
import styled from 'styled-components';

// Local Imports
import theme from './Theme';
import CustomIcon from '../shared/CustomIcon';

const StyledButton = styled(Button)({
	width: (props) => props.width || '100%',
	height: (props) => props.height || '40px',
	textTransform: (props) =>
		props.uppercase === 'true' ? 'uppercase !important' : 'none !important',
	fontSize: (props) => `${props.fontSize} !important` || '0.875rem !important',

	'@media (max-width: 320px)': {
		fontSize: '1rem !important',
	},

	'&.MuiButton-root': {
		color: (props) =>
			props.variant === 'outlined' ? 'var(--secondary)' : '#fff',
		backgroundColor: (props) => props.btncolor || theme.palette.primary.main,
		borderRadius: 10,
		marginTop: (props) => props.margintop || '8px',
		border: (props) =>
			props.variant === 'outlined' && '2px solid var(--secondary)',
	},

	'&.MuiButton-root:hover': {
		color: (props) => props.btntexthovercolor || '#fff',
		backgroundColor: (props) =>
			props.btnhovercolor || theme.palette.primary.main,
		border: (props) =>
			props.variant === 'outlined'
				? `2px solid ${props.btntexthovercolor}`
				: 'none',
	},

	'&.MuiButton-root:active': {
		color: (props) => props.btntexthovercolor || '#fff',
		backgroundColor: (props) =>
			props.btnhovercolor || theme.palette.secondary.main,
	},

	'&.MuiButton-root:disabled': {
		color: '#fff',
		backgroundColor: (props) =>
			props.btndisabledcolor || theme.palette.primary.light,
	},
});

const CustomBtn = (props) => {
	// Props destructuring
	const {
		btnColor,
		btnDisabledColor,
		btnHoverColor,
		btnText,
		btnType,
		height,
		isDisabled,
		marginTop,
		btnLoadingText,
		btnUppercase,
		btnTextHoverColor,
		fontSize,
		onClick,
		startIcon,
		variant,
		width,
	} = props;

	return (
		<StyledButton
			type={btnType}
			disabled={isDisabled}
			onClick={onClick}
			btncolor={btnColor}
			btndisabledcolor={btnDisabledColor}
			btnhovercolor={btnHoverColor}
			btntexthovercolor={btnTextHoverColor}
			width={width}
			height={height}
			margintop={marginTop}
			uppercase={btnUppercase}
			fontSize={fontSize}
			variant={variant}
			startIcon={
				startIcon ? (
					<CustomIcon iconName={startIcon} iconSize={fontSize} />
				) : null
			}
		>
			{isDisabled ? btnLoadingText : btnText}
		</StyledButton>
	);
};

CustomBtn.propTypes = {
	btnText: PropTypes.string.isRequired,
	btnType: PropTypes.string.isRequired,
	btnColor: PropTypes.string,
	btnHoverColor: PropTypes.string,
	btnTextHoverColor: PropTypes.string,
	btnDisabledColor: PropTypes.string,
	btnLoadingText: PropTypes.string,
	btnUppercase: PropTypes.string,
	fontSize: PropTypes.string,
	marginTop: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	isDisabled: PropTypes.bool,
	onClick: PropTypes.func,
	startIcon: PropTypes.string,
	variant: PropTypes.string,
};

export default CustomBtn;
