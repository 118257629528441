import { combineReducers } from 'redux';
import adminReducer from './adminReducer';
import appOptionsReducer from './appOptionsReducer';
import authReducer from './authReducer';
import dashboardReducer from './dashboardReducer';
import innerRouterReducer from './innerRouterReducer';
import kycReducer from './kycReducer';
import notificationsReducer from './notificationsReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
	admin: adminReducer,
	appOptions: appOptionsReducer,
	auth: authReducer,
	dashboard: dashboardReducer,
	innerRouter: innerRouterReducer,
	kyc: kycReducer,
	notifications: notificationsReducer,
	user: userReducer,
});

export default rootReducer;
