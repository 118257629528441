const initState = {
	loading: false,
	userProfile: null,
	closeDialog: false,
	closeBackdrop: false,
};

const userReducer = (state = initState, action) => {
	switch (action.type) {
		case 'UPDATE_USER_START':
		case 'GET_USER_START':
			return {
				...state,
				loading: true,
			};
		case 'GET_USER_SUCCESS':
			return {
				...state,
				userProfile: action.payload,
				loading: false,
			};
		case 'UPDATE_USER_SUCCESS':
			return {
				...state,
				userProfile: action.payload,
				loading: false,
				closeDialog: true,
				closeBackdrop: true,
			};
		case 'UPDATE_USER_END':
			return {
				...state,
				closeDialog: false,
				closeBackdrop: false,
			};
		case 'UPDATE_USER_ERROR':
		case 'GET_USER_ERROR':
			return {
				...state,
				loading: false,
			};
		case 'SIGNOUT':
			return {
				...state,
				userProfile: null,
			};
		case 'UPDATE_USER_PROFILE_PHOTO_ERROR':
			return {
				...state,
				closeBackdrop: true,
			};

		default:
			return state;
	}
};

export default userReducer;
